import { createAsync } from '@solidjs/router';
import { gql } from '@urql/core';
import { Show, Suspense } from 'solid-js';
import { CardsCtx } from '../providers/card';
import { cachedQuery } from '../graphql/cached-get';
import type { RouteSectionProps } from '@solidjs/router';

export default function CardsPageLayout(props: RouteSectionProps) {
	const data = createAsync(() => getCards({}), { deferStream: true });

	return (
		<Suspense>
			<Show when={data()?.cards}>
				{(cards) => <CardsCtx.Provider value={cards}>{props.children}</CardsCtx.Provider>}
			</Show>
		</Suspense>
	);
}

const query = gql(`query troonCards {
  cards: troonCardSubscriptionProducts {
    id
    active
    subscriptionName
    amount {
      value
      displayValue
    }
    facilityName
  }
}`);

const getCards = cachedQuery(query);
